<template>
  <div class="main-container">
    <div>
      <div class="marker flex-wrap flex-h-center">
        <div class="horn-title-style">服务机构</div>
        <div class="flex-con flex-wrap flex-h-center flex-between">
          <div class="menu-nav">服务机构<a-icon type="right" />机构详情</div>
          <div class="back-btn" @click="$router.go(-1)">返回</div>
        </div>
      </div>
      <div class="synopsis flex-wrap">
        <div class="pic">
          <img :src="mechanismData.pic" alt="" width="150" height="150" />
        </div>
        <div class="info">
          <div class="name">{{ mechanismData.name }}</div>
          <div class="phone">联系方式：{{ mechanismData.phone }}</div>
          <div class="trade">
            所属行业：{{ setDict('park_customer_trade', mechanismData.trade) }}
          </div>
          <div class="introduction">简介：{{ mechanismData.introduction }}</div>
        </div>
      </div>

      <div class="exhibition">
        <div class="tab-nav">
          <ul>
            <li class="active">机构介绍</li>
          </ul>
        </div>
        <div class="tab-content">
          <div v-html="mechanismData.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dictQueryAllDictItems } from '@/api/api'
export default {
  data() {
    return {
      mechanismData: {},
      dictData: {},
    }
  },
  created() {
    this.gitAllDict()
    if (this.$route.params.data) {
      this.mechanismData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      console.log(this.mechanismData)
    }
  },
  methods: {
    async gitAllDict() {
      try {
        const res = await dictQueryAllDictItems({})
        if (res.success) {
          this.dictData = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    setDict(code, value) {
      if (this.dictData[code]) {
        let result = this.dictData[code].find((item) => {
          return item.value == value
        })
        if (result) {
          return result.text
        } else {
          return ''
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.main-container {
  background: #f4f4f4;

  ul {
    margin: 0;
  }

  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    .marker {
      border-bottom: 1px solid #e5e5e5;
      background: #fff;

      .menu-nav {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-left: 17px;
      }

      .back-btn {
        width: 109px;
        height: 39px;
        line-height: 39px;
        background: #1285d6;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-right: 34px;
        cursor: pointer;
      }
    }

    .synopsis {
      background: #fff;
      padding: 30px 15px 15px;

      .pic {
        margin-right: 20px;
      }

      .info {
        color: #666;
        > div {
          margin-bottom: 10px;
        }
        .name {
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }
      }
    }

    .exhibition {
      background: #fff;
      margin-top: 20px;

      .tab-nav {
        padding: 10px 15px;
        border-bottom: 1px solid #e5e5e5;
        li {
          font-size: 18px;
          font-weight: bold;

          &.active {
            color: #1285d6;
          }
        }
      }

      .tab-content {
        padding: 25px;
      }
    }
  }

  .horn-title-style {
    width: 160px;
    height: 55px;
    line-height: 55px;
    background-color: #1285d6;
    position: relative;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: rgb(18, 133, 214);
      top: 100%;
      left: 50%;
      position: absolute;
      margin-left: -12px;
    }
  }

  .content {
    min-height: 600px;
    padding: 50px 30px;
  }
}
</style>
