<template>
  <div class="main-container">
    <div>
      <div class="marker flex-wrap flex-h-center">
        <div class="horn-title-style">入驻企业</div>
        <div class="flex-con flex-wrap flex-h-center flex-between">
          <div class="menu-nav">入驻企业<a-icon type="right" />企业详情</div>
          <div class="back-btn" @click="$router.go(-1)">返回</div>
        </div>
      </div>
      <div class="synopsis flex-wrap">
        <div class="pic">
          <img
            :src="enterpriseData.enterprisePic"
            alt=""
            width="150"
            height="150"
            v-show="enterpriseData.enterprisePic"
          />
          <img
            src="@/assets/zwtp.png"
            alt=""
            width="150"
            height="150"
            v-show="!enterpriseData.enterprisePic"
          />
        </div>
        <div class="info">
          <div class="name">
            {{ enterpriseData.enterpriseName }}
          </div>
          <div class="phone">
            联系方式：{{
              enterpriseData.phone ? enterpriseData.phone : '未填写'
            }}
          </div>
          <div class="trade">
            所属行业：{{
              setDict('park_customer_trade', enterpriseData.trade)
                ? setDict('park_customer_trade', enterpriseData.trade)
                : '未填写'
            }}
          </div>
          <!-- <div class="introduction">简介：{{ enterpriseData.discription }}</div> -->
        </div>
      </div>

      <div class="exhibition">
        <div class="tab-nav">
          <ul class="flex-wrap flex-h-center">
            <li
              :class="[tabIndex === 0 ? 'active' : '']"
              @click="
                () => {
                  tabIndex = 0
                }
              "
            >
              企业介绍
            </li>
            <li
              :class="[tabIndex === 1 ? 'active' : '']"
              @click="
                () => {
                  tabIndex = 1
                }
              "
            >
              企业招聘
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div v-show="tabIndex === 0">
            <div
              class="flex-wrap flex-vertical flex-center not-img"
              v-if="!enterpriseData.discription"
            >
              <img src="@/assets/zwsj.png" alt="" />
              <div>未填写</div>
            </div>
            <div class="introduce" v-if="enterpriseData.discription">
              {{ enterpriseData.discription }}
            </div>
          </div>
          <div v-show="tabIndex === 1" class="recruit">
            <div
              class="flex-wrap flex-vertical flex-center not-img"
              v-if="positionList.length <= 0"
            >
              <img src="@/assets/zwsj.png" alt="" />
              <div>没有数据</div>
            </div>
            <ul v-if="positionList.length > 0">
              <li
                @click="openDetails(item)"
                v-for="(item, index) in positionList"
                :key="index"
              >
                <div class="position-name">{{ item.position }}</div>
                <div class="flex-wrap flex-h-center">
                  <span class="salary">{{ item.money }}</span
                  ><span class="education">
                    {{
                      item.experience == '经验不限'
                        ? item.experience
                        : item.experience + '年'
                    }}
                    I {{ item.education }}</span
                  >
                </div>
              </li>
            </ul>

            <div class="paging">
              <a-pagination
                v-model="page.current"
                :pageSize="page.pageSize"
                :total="page.total"
                hideOnSinglePage
                @change="changePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  dictQueryAllDictItems,
  yqmsRecruitlListByEnterpriseId,
} from '@/api/api'
export default {
  data() {
    return {
      enterpriseData: {},
      dictData: {},
      tabIndex: 0,
      page: {
        pageSize: 5,
        current: 1,
        total: 0,
      },
      positionList: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'EnterprisePositionDetails') {
      this.tabIndex = 0
    }
    next()
  },
  created() {
    this.gitAllDict()
    if (this.$route.params.data) {
      this.enterpriseData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      this.getRecruitList()
    }
  },
  activated() {
    this.gitAllDict()
    if (this.$route.params.data) {
      this.enterpriseData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      this.getRecruitList()
    }
  },
  methods: {
    async gitAllDict() {
      try {
        const res = await dictQueryAllDictItems({})
        if (res.success) {
          this.dictData = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    setDict(code, value) {
      if (this.dictData[code]) {
        let result = this.dictData[code].find((item) => {
          return item.value == value
        })
        if (result) {
          return result.text
        } else {
          return ''
        }
      }
    },
    async getRecruitList() {
      try {
        const params = {
          enterpriseId: this.enterpriseData.id,
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
        }
        const res = await yqmsRecruitlListByEnterpriseId(params)
        if (res.success) {
          this.positionList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    changePage(page) {
      this.page.current = page
      this.getRecruitList()
    },
    openDetails(item) {
      this.$router.push({
        name: 'EnterprisePositionDetails',
        params: {
          data: window.btoa(encodeURIComponent(JSON.stringify(item))),
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.main-container {
  background: #f4f4f4;

  ul {
    margin: 0;
  }

  > div {
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 50px 0;

    .marker {
      border-bottom: 1px solid #e5e5e5;
      background: #fff;

      .menu-nav {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-left: 17px;
      }

      .back-btn {
        width: 109px;
        height: 39px;
        line-height: 39px;
        background: #1285d6;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-right: 34px;
        cursor: pointer;
      }
    }

    .synopsis {
      background: #fff;
      padding: 30px 15px 15px;

      .pic {
        margin-right: 20px;
      }

      .info {
        color: #666;
        > div {
          margin-bottom: 10px;
        }
        .name {
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }
      }
    }

    .exhibition {
      background: #fff;
      margin-top: 20px;

      .tab-nav {
        padding: 10px 15px;
        border-bottom: 1px solid #e5e5e5;
        li {
          font-size: 18px;
          margin-right: 20px;
          cursor: pointer;

          &.active {
            color: #1285d6;
            font-weight: bold;
          }

          &:hover {
            color: #1285d6;
          }
        }
      }

      .tab-content {
        .introduce {
          padding: 25px;
        }
        .recruit {
          ul {
            li {
              padding: 25px;
              border-bottom: 1px solid #e5e5e5;
              cursor: pointer;

              &:last-child {
                border: none;
              }

              .position-name {
                color: #666;
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 16px;
              }

              .salary {
                font-size: 16px;
                color: #ff9a15;
              }

              .education {
                font-size: 12px;
                color: #999999;
                margin-left: 10px;
              }
            }
          }

          .paging {
            text-align: right;
            padding: 25px;
          }
        }
      }
    }
  }

  .horn-title-style {
    width: 160px;
    height: 55px;
    line-height: 55px;
    background-color: #1285d6;
    position: relative;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: rgb(18, 133, 214);
      top: 100%;
      left: 50%;
      position: absolute;
      margin-left: -12px;
    }
  }

  .content {
    min-height: 600px;
    padding: 50px 30px;
  }
}

.not-img {
  font-size: 18px;
  color: #9cb9fa;
  padding: 50px 0;
  > div {
    margin-top: 20px;
  }
}
</style>
