<template>
  <div class="conetent">
    <div class="example" v-if="loading">
      <a-spin size="large" />
    </div>
    <ul class="flex-wrap flex-wrap-wrap" v-else>
      <li
        v-for="(item, index) in enterpriseList"
        :key="index"
        @click="openEnterpriseDetails(item)"
      >
        <div class="activity-img">
          <img :src="item.enterprisePic" alt="" />
        </div>
        <div class="activity-info">
          <div class="name t-hide">{{ item.enterpriseName }}</div>
        </div>
      </li>
    </ul>

    <div class="paging">
      <a-pagination
        v-model="page.current"
        :pageSize="page.pageSize"
        :total="page.total"
        hideOnSinglePage
        @change="changePage"
      />
    </div>
  </div>
</template>
<script>
import { yqmsEnterpriseList } from '@/api/api'
export default {
  components: {},
  data() {
    return {
      page: {
        pageSize: 9,
        current: 1,
        total: 0,
      },
      enterpriseList: [],
      loading: false,
    }
  },
  activated() {
    this.getEnterpriseList()
  },
  created() {
    this.getEnterpriseList()
  },
  methods: {
    async getEnterpriseList() {
      this.loading = true
      try {
        const params = {
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
        }
        const res = await yqmsEnterpriseList(params)
        if (res.success) {
          this.enterpriseList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },

    changePage(page) {
      this.page.current = page
      this.getEnterpriseList()
    },

    openEnterpriseDetails(data) {
      this.$router.push({
        name: 'EnterpriseDetails',
        params: {
          data: window.btoa(encodeURIComponent(JSON.stringify(data))),
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.conetent {
  min-height: 600px;
  background: #fff;
  padding: 30px;
  ul {
    margin: 0;
    li {
      width: 238px;
      border: 1px solid #eee;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 15px;
      margin-left: 13px;
      .activity-img {
        width: 100%;
        img {
          width: 100%;
          height: 150px;
          // max-width: 100%;
          // max-height: 150px;
          // border-radius: 5px 5px 0 0;
        }
      }

      .activity-info {
        padding: 20px;
        .name {
          font-size: 18px;
          color: #333333;
          text-align: center;
        }
      }
    }
  }

  .example {
    text-align: center;
    padding: 200px 0;
  }

  .paging {
    text-align: right;
    padding: 20px 0 0 0;
  }
}
</style>
