<template>
  <div class="main-body">
    <div class="flex-wrap">
      <div class="nav">
        <ul>
          <li
            :class="[tabIndex === 0 ? 'active' : '']"
            @click="
              () => {
                tabIndex = 0
              }
            "
          >
            服务机构
          </li>
          <li
            :class="[tabIndex === 1 ? 'active' : '']"
            @click="
              () => {
                tabIndex = 1
              }
            "
          >
            入驻企业
          </li>
        </ul>
      </div>
      <div class="content flex-con">
        <div v-show="tabIndex === 0">
          <mechanism />
        </div>
        <div v-show="tabIndex === 1">
          <enterprise />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mechanism from './Mechanism/Mechanism'
import Enterprise from './Enterprise/Enterprise'
export default {
  components: {
    Mechanism,
    Enterprise,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  created() {},
  beforeRouteLeave(to, from, next) {
    if (to.name != 'MechanismDetails' && to.name != 'EnterpriseDetails') {
      this.tabIndex = 0
    }
    next()
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.main-body {
  background: #f4f4f4;
  > div {
    width: 1200px;
    padding: 50px 0;
    margin: 0 auto;

    .nav {
      ul {
        li {
          list-style: none;
          font-size: 18px;
          font-weight: 400;
          color: #555555;
          background: #fff;
          padding: 26px 145px;
          border-bottom: 1px dashed #ccc;
          cursor: pointer;

          &.active {
            color: #1285d6;
            background: rgba(18, 133, 214, 0.2);
          }

          &:last-child {
            border: none;
          }

          &:hover {
            color: #1285d6;
            background: rgba(18, 133, 214, 0.2);
          }
        }
      }
    }

    .content {
      margin-left: 25px;
    }
  }
}
</style>
